import React, {useEffect, useState} from "react";
import {Form, Input, Row, Col, Select} from "antd";
import ParserAgent from "../ParserAgent";
import RemoteAgent from "../RemoteAgent";

const { Item } = Form;

export default function AgentForm({
  isEdit,
  agent,
  regularExpressions = [],
  token,
  onFinish,
}) {
  const [type, setType] = useState('')
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(agent);
    if(agent?.type) {
      setType(agent.type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  return (
    <Form
      form={form}
      id={isEdit ? "agent-edit-form" : 'agent-create-form'}
      layout="vertical"
      onFinish={() => {
        const values = form.getFieldsValue()
        setType('')
        onFinish({...values, id: agent?.id});
      }}
    >
      <Row>
        <Col>
          <Item
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={5}>
          <Item
              initialValue={""}
              label="Description"
              name="description"
          >
            <Input name="description" />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Item
            initialValue={""}
            label="Type"
            name="type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select style={{ width: '100%' }} onSelect={(val) => {
              setType(val)
            }}>
              <Select.Option value="parser" key="parser">
                Parser
              </Select.Option>
              <Select.Option value="remote" key="remote">
                Remote
              </Select.Option>
            </Select>
          </Item>
        </Col>
      </Row>

      {type === 'parser' &&
        <ParserAgent form={form} token={token} regularExpressions={regularExpressions} />
      }
      {type === 'remote' &&
        <RemoteAgent form={form} token={token} regularExpressions={regularExpressions} />
      }
    </Form>
  );
}
