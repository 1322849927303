import React, { useEffect } from "react";
import {Form, Input, Row, Col, Select} from "antd";

const { Item } = Form;

export default function TagModelForm({
  isEdit,
  tagModel,
  tagTypes,
  onInputChange,
  onTypeChange,
  onFinish
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(tagModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagModel]);

  return (
    <Form
      form={form}
      id={isEdit ? "tag-model-edit-form" : 'tag-model-create-form'}
      layout="vertical"
      onChange={onInputChange}
      onFinish={() => {
        form.resetFields();
        onFinish();
      }}
    >
      <Row>
        <Col>
          <Item
            style={{ width: 140 }}
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={2}>
          <Item
            style={{ width: 120 }}
            initialValue={""}
            label="Type"
            name="typeId"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select
              name="typeId"
              onChange={onTypeChange}
              options={tagTypes.map((item) => {
                return {
                  value: item.id,
                  label: item.name
                }
              })}
            />
          </Item>
        </Col>

        <Col offset={2}>
          <Item
            style={{ width: 120 }}
            initialValue={""}
            label="Description"
            name="description"
          >
            <Input name="description" />
          </Item>
        </Col>
      </Row>
    </Form>
  );
}
