import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Form, Select, Tabs } from "antd";

import { URLS } from "../../constants";
import { ContentInner } from "../styled";
import TagModels from "../../components/TagModels";
import TagManufacturers from "../../components/TagManufacturers";
import TagTypes from "../../components/TagTypes";
import RegularExpressions from "../../components/RegularExpressions";
import Agents from "../../components/Agents";

const { Item } = Form;

export default function TagSettings({ token }) {
  const [environment, setEnvironment] = useState(null);

  const { data: envData } = useQuery(
    "envData",
    () =>
      axios
        .get(URLS.FETCH_ENVIRONMENT, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      initialData: [],
    }
  );

  const onEnvironmentSelect = (value) => {
    setEnvironment(value);
  };

  return (
    <ContentInner>
      <strong style={{ marginBottom: "6px" }}>Select the environment</strong>
      <Item style={{ width: "50%" }} label="Environment">
        <Select onSelect={onEnvironmentSelect}>
          {envData.map((env) => (
            <Select.Option value={env.name} key={env.name}>
              {env.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      {environment && (
        <>
          <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            <Tabs.TabPane tab="Tag Models" key="1">
              <TagModels token={token} environment={environment}></TagModels>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tag Manufacturers" key="2">
              <TagManufacturers token={token} environment={environment}></TagManufacturers>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tag Types" key="3">
              <TagTypes token={token} environment={environment}></TagTypes>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Regular Expressions" key="4">
              <RegularExpressions token={token} environment={environment}></RegularExpressions>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Agents" key="5">
              <Agents token={token} environment={environment}></Agents>
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </ContentInner>
  );
}
