import React, { useEffect, useState } from "react";
import {Button, Form, Select, Modal, notification} from "antd";
import { v4 as uuidv4 } from "uuid";
import {PlusOutlined} from "@ant-design/icons";
import { useQuery } from "react-query";
import axios from "axios";

import { TagManagerForm } from "../../components/Forms";
import { useModal, useSocket } from "../../hooks";
import {
  EVENTS,
  ROLL_MODEL,
  TAG_AUTH_SERVICE,
  URLS,
} from "../../constants";
import { TopControlsBlock, ContentInner, Link } from "../styled";
import { getOverageAsPercentage } from "../../helpers";

const { Item } = Form;

export default function TagManager({ token }) {
  const [form] = Form.useForm();
  const [selectedEnvironment, setEnvironment] = useState(null);
  const [
    isModalShown,
    setModalShown,
    onModalOkAction,
    onModalCancelAction,
  ] = useModal();
  const [tagManufacturers, setTagManufacturers] = useState([]);
  const [tagModels, setTagModels] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);
  const [regularExpressions, setRegularExpressions] = useState([]);
  const [agents, setAgents] = useState([]);
  const [orders, updateOrders] = useState([]);
  const [response, setResponse] = useState([]);

  const { data, send, get } = useSocket(EVENTS.TAG_MANAGER_CREATE);

  useEffect(() => {
    if (data) {
      data.forEach((res) => setResponse((prev) => [...prev, res]));
    }
  }, [data]);

  const { data: envData } = useQuery(
    "envData",
    () =>
      axios
        .get(URLS.FETCH_ENVIRONMENT, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      initialData: [],
    }
  );

  const { data: companiesData } = useQuery(
    ["companiesData", selectedEnvironment],
    () =>
      axios
        .get(URLS.FETCH_COMPANIES, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            env: selectedEnvironment,
          },
        })
        .then((res) => res.data),
    { initialData: [], enabled: !!selectedEnvironment }
  );

  const updateRoll = (field, value, rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, [field]: value };
      })
    );
  };

  const updateQRCOptions = (field, value, rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, qrc: { ...order.qrc, [field]: value } };
      })
    );
  };

  const resetQRCOptions = (rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, qrc: { ...ROLL_MODEL.qrc } };
      })
    );
  };

  const onParserAgentChange = (rollId) => (parserAgent) => {
    const order = orders.find((item) => item.id === rollId)
    const agents = order.agents || []
    const filteredAgents = agents.filter((item) => item.type !== 'parserAgent')
    if (!parserAgent || !parserAgent?.length) {
      updateRoll('agents', [...filteredAgents], rollId);
      return
    }
    updateRoll('agents', [...filteredAgents, {
      type: 'parserAgent',
      settings: parserAgent
    }], rollId);
  };

  const onRemoteAgentChange = (rollId) => (remoteAgent) => {
    const order = orders.find((item) => item.id === rollId)
    const agents = order.agents || []
    const filteredAgents = agents.filter((item) => item.type !== 'remoteAgent')
    if (!remoteAgent || !remoteAgent?.length) {
      updateRoll('agents', [...filteredAgents], rollId);
      return
    }
    updateRoll('agents', [...filteredAgents, {
      type: 'remoteAgent',
      settings: remoteAgent
    }], rollId);
  };

  const onInputChange = (rollId) => (e) => {
    const { name, value } = e.target;
    if (name) {
      updateRoll(name, value, rollId);
    }
  };

  const onOptionsChange = (rollId) => (e) => {
    const { name, value } = e.target;
    updateQRCOptions(name, value, rollId);
  };

  const onOptionsReset = (rollId) => (e) => {
    resetQRCOptions(rollId);
  };

  const onCompanySelect = (rollId) => (companyId) => {
    updateRoll("companyId", companyId, rollId);
  };

  const onTagManufacturerSelect = (rollId) => (value) => {
    const tagManufacturer = tagManufacturers.find((item) => item.id === value)
    updateRoll("tagManufacturer", tagManufacturer.name, rollId);
    updateRoll("tagManufacturerId", value, rollId);
  };

  const onTagTypeSelect = (rollId) => (type) => {
    const tagType = tagTypes.find((item) => item.id === type)
    updateRoll("tagType", tagType.name, rollId);
    updateRoll("tagTypeId", type, rollId);
    // reset tag model
    updateRoll("tagModelId", '', rollId);
  };

  const onTagModelSelect = (rollId) => (value) => {
    const tagModel = tagModels.find((item) => item.id === value)
    updateRoll("tagModel", tagModel.name, rollId);
    updateRoll("tagModelId", value, rollId);
  };

  const onTagAuthServiceSelect = (rollId) => (model) => {
    updateRoll("tagAuthService", model, rollId);
  };

  const onEnvironmentSelect = (value) => {
    // if (!orders.length) {
    //   addRoll()
    // }
    setEnvironment(value);
  };

  const addRoll = () => {
    const newOrders = [...orders, { id: uuidv4(), ...ROLL_MODEL }]
    updateOrders(newOrders);
  };

  const onDelete = (rollId) => () => {
    const newOrders = orders.filter(({ id }) => id !== rollId)
    updateOrders([...newOrders]);
  };

  const onClone = (rollId) => () => {
    const order = orders.find(({ id }) => id === rollId);
    updateOrders((orders) => [...orders, { ...order, id: uuidv4() }]);
  };

  const getTotalTagsSize = (orderId) => {
    const order = orders.find((el) => el.id === orderId);
    if (!order) {
      return;
    }
    const totalSize = +order.size + getOverageAsPercentage(order);
    if (totalSize > 99) {
      notification.error({
        message:
          "The total number of tags cannot exceed 99 tags in one order.",
        duration: 5,
      });
      updateRoll("size", "", order.id);
      return;
    }
    return +order.size + getOverageAsPercentage(order);
  };

  const getTotalOverageInPercent = (orderId) =>
    orders.map(
      (el) =>
        orderId === el.id &&
        `${+el.overage}% (${getOverageAsPercentage(el)} tags)`
    );

  const create = async () => {
    await form.validateFields()
    if (!orders.length) {
      notification.error({
        message: "Please add at least one order"
      });
      return
    }

    send(EVENTS.TAG_MANAGER_CREATE, {
      orders: orders.map((order) => {
        return {
          ...order,
          overage: Math.round(+(+order.size * +order.overage) / 100),
          tagsPerRoll: getTotalTagsSize(order.id) / +order.rolls,
        };
      }),
      env: selectedEnvironment,
    });
  };

  const getTagManufacturers = async () => {
    axios
      .get(URLS.FETCH_TAG_MANUFACTURERS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: selectedEnvironment,
        },
      })
      .then((res) => {
        setTagManufacturers(res.data);
      });
  };

  const getTagModels = async () => {
    axios
      .get(URLS.FETCH_TAG_MODELS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: selectedEnvironment,
        },
      })
      .then((res) => {
        setTagModels(res.data);
      });
  };

  const getTagTypes = async () => {
    axios
      .get(URLS.FETCH_TAG_TYPES, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: selectedEnvironment,
        },
      })
      .then((res) => {
        setTagTypes(res.data);
      });
  };

  const getRegularExpressions = async () => {
    axios
      .get(URLS.FETCH_REGULAR_EXPRESSIONS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: selectedEnvironment,
        },
      })
      .then((res) => {
        setRegularExpressions(res.data);
      });
  };

  const getAgents = async () => {
    axios
      .get(URLS.FETCH_AGENTS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: selectedEnvironment,
        },
      })
      .then((res) => {
        setAgents(res.data);
      });
  };

  useEffect(() => {
    get(EVENTS.TAG_MANAGER_CREATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEnvironment) {
      getTagManufacturers();
      getTagModels();
      getTagTypes();
      getRegularExpressions();
      getAgents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvironment]);

  return (
    <>
      <TopControlsBlock width="260px">
        <Button
          type="primary"
          onClick={setModalShown()}
          disabled={!response[0]}
        >
          Download order
        </Button>
        <Modal
          title="Download order"
          width={750}
          visible={isModalShown}
          onOk={onModalOkAction()}
          onCancel={onModalCancelAction()}
        >
          {response.map((link) => (
            <div key={link}>
              Download:{" "}
              <Link disabled={!response[0]} download="sm.zip" href={link}>
                {link}
              </Link>
              <br />
            </div>
          ))}
        </Modal>
        <Button onClick={create} type="primary">
          Create orders
        </Button>
      </TopControlsBlock>
      <ContentInner>
        <strong style={{ marginBottom: "6px" }}>Select the environment</strong>
        <Item style={{ width: "50%" }} label="Environment">
          <Select onSelect={onEnvironmentSelect}>
            {envData.map((env) => (
              <Select.Option value={env.name} key={env.name}>
                {env.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Form layout="horizontal" form={form}  onValuesChange={(changedValues) => {
          const formOrders = changedValues.orders || []
          formOrders.forEach((item, index) => {
            let agentsData = []
            const order = orders[index]
            const formAgents = form.getFieldValue(['orders', index, 'agents']) || []
            if (formAgents && formAgents.length) {
              agentsData = formAgents.filter((item) => item && item.type).map((item) => {
                const settings = agents.find((agent) => agent.id === item?.agentId)?.settings || []
                return {
                  ...item,
                  settings
                }
              })
            }

            if (order?.id) {
              updateRoll('agentsAmount', formAgents.length, order.id);
              updateRoll('agents', agentsData, order.id);
            }
          })
        }}>
          <Form.List name="orders">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => {
                  const order = orders[index]

                  return (
                    <>
                      <TopControlsBlock
                        style={{ marginBottom: "15px" }}
                        align="flex-start"
                        width="100%"
                      >
                        <ContentInner>
                          <strong>Order Information: </strong>
                          Total rolls: {order.rolls || 0}; Total tags:{" "}
                          {getTotalTagsSize(order.id) || 0}; Tag overage:{" "}
                          {getTotalOverageInPercent(order.id)};
                        </ContentInner>
                      </TopControlsBlock>

                      <TagManagerForm
                        token={token}
                        form={form}
                        field={field}
                        order={order}
                        key={order.id}
                        tagAuthService={TAG_AUTH_SERVICE}
                        tagTypes={tagTypes}
                        tagModels={tagModels}
                        tagManufacturer={tagManufacturers}
                        regularExpressions={regularExpressions}
                        agents={agents}
                        companies={companiesData}
                        onParserAgentChange={onParserAgentChange(order.id)}
                        onRemoteAgentChange={onRemoteAgentChange(order.id)}
                        onInputChange={onInputChange(order.id)}
                        onCompanySelect={onCompanySelect(order.id)}
                        onTagModelSelect={onTagModelSelect(order.id)}
                        onTagAuthServiceSelect={onTagAuthServiceSelect(order.id)}
                        onTagTypeSelect={onTagTypeSelect(order.id)}
                        onDelete={() => {
                          onDelete(order.id)()
                          remove(index)
                        }}
                        onClone={() => {
                          onClone(order.id)()
                          add({...order})
                        }}
                        onOptionsChange={onOptionsChange(order.id)}
                        onReset={onOptionsReset(order.id)}
                        onTagManufacturerSelect={onTagManufacturerSelect(order.id)}
                      />
                    </>
                  )
                })}

                {selectedEnvironment && (
                  <>
                    <Button type="default" style={{width: '100%'}} icon={<PlusOutlined />} onClick={() => {
                      addRoll()
                      add()
                    }}>
                      Add order
                    </Button>
                  </>
                )}
              </>
            )}
          </Form.List>
        </Form>
      </ContentInner>
    </>
  );
}
