import React from "react";
import {Button, Col, Form, Input, Modal, Row, Select, Tooltip} from "antd";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";
import {useModal} from "../../hooks";
import ActivationParamsForm from "../Forms/activation-params";
const { Item } = Form;

const parametersMapping = [
  {
    value: 'm',
    name: 'Manufacturer ID'
  },
  {
    value: 'c',
    name: 'Counter'
  },
  {
    value: 'state',
    name: 'State'
  }
]

export default function ParserAgent({ form, regularExpressions }) {
  const [isModalShown, setModalShown, , onModalCancelAction] =
    useModal();
  const params = (form.getFieldValue(['settings']) || []).filter(Boolean)


  return (
    <>
      <Modal
        title="Test activation params parser"
        okText="Test"
        visible={isModalShown}
        onOk={() => {}}
        onCancel={() => {
          onModalCancelAction()();
        }}
        okButtonProps={{
          form: "test-activation-params",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <ActivationParamsForm  params={params} />
      </Modal>
      <Form.List name={['settings']}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Row>
                <Col span={24}>
                  <Row  style={{alignItems: 'center'}}>
                    <Col span={12}>
                      <Item
                        name={[field.name, 'name']}
                        label="Parameter name"
                        rules={[{ required: true, message: "Parameter name required" }]}
                      >
                        <Select>
                          {parametersMapping.map(({name, value}) => (
                            <Select.Option value={value} key={value}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>
                    <Col offset={1} span={8}>
                      <Item
                        name={[field.name, 'value']}
                        label="RegExp"
                        rules={[{ required: true, message: "RegExp is required" }]}
                      >
                        <Select>
                          {regularExpressions.map(({name, expression}) => (
                            <Select.Option value={expression} key={expression}>
                              <Tooltip title={expression}>
                                {name}
                              </Tooltip>
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>

                    {index > 0 ? (
                      <Col offset={1} span={2}>
                        <Button type="primary"  shape="circle" icon={<MinusOutlined />} onClick={() => {
                          remove(field.name)
                        }} danger ></Button>
                      </Col>
                    ) : null}
                  </Row>

                  <Form.List name={[field.name, 'valueMappings']}>
                    {(valueMappingFields, {add: addValueMapping, remove: removeValueMapping}, { errors }) => (
                      <>
                        {valueMappingFields.map((valueMappingField, valueMappingIndex) => (
                          <Row style={{alignItems: 'center'}}>
                            <Col span={9}>
                              <Item
                                name={[valueMappingField.name, 'valueRegexp']}
                                label="Value RegExp"
                              >
                                <Input />
                              </Item>
                            </Col>
                            <Col offset={1} span={9}>
                              <Item
                                name={[valueMappingField.name, 'valueMapping']}
                                label="Value mapping"
                              >
                                <Input />
                              </Item>
                            </Col>
                            <Col offset={1} span={2}>
                              <Button type="primary"  shape="circle" icon={<MinusOutlined />} onClick={() => {
                                removeValueMapping(valueMappingField.name)
                              }} danger ></Button>
                            </Col>
                          </Row>
                        ))}

                        <Row justify="center">
                          <Item>
                            <Button type="primary"   onClick={() => addValueMapping()}>Add mapping</Button>
                          </Item>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Col>

                {/*<Col offset={1} span={23}>*/}
                {/*  */}
                {/*</Col>*/}
              </Row>
            ))}

            <Row justify="center">
              <Item>
                <Button
                  type="primary"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add parameter
                </Button>
                <Button
                  style={{marginLeft: '8px'}}
                  type="primary"
                  onClick={setModalShown()}
                >
                  Test
                </Button>
              </Item>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
}
