import React, {useEffect, useState} from "react";
import { Form, Input, Row, Col } from "antd";

const { Item } = Form;

export default function RegularExpressionForm({
  isEdit,
  regularExpression,
  onInputChange,
  onFinish
}) {
  const [example, setExample] = useState('')
  const [expression, setExpression] = useState('')
  const [highlightedStr, setHighlightedStr] = useState('')
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(regularExpression);
    if(regularExpression?.example) {
      setExample(regularExpression.example)
    }
    if(regularExpression?.expression) {
      setExpression(regularExpression.expression)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularExpression]);

  useEffect(() => {
    if (expression && example) {
      const [, secondValue] = example.match(new RegExp(expression)) || []

      // if (!secondValue) {
      //   const str = example.replaceAll(new RegExp(expression), (a) => {
      //     return `<span style="color: red">${a}</span>`
      //   })
      //   setHighlightedStr(str)
      //   return
      // }

      if (secondValue) {
        const str = example.replaceAll(secondValue, `<span style="color: red">${secondValue}</span>`)
        setHighlightedStr(str)
        return
      }

      setHighlightedStr(example)
    } else {
      setHighlightedStr('')
    }
  }, [expression, example]);

  return (
    <Form
      form={form}
      id={isEdit ? "regular-expression-edit-form" : 'regular-expression-create-form'}
      layout="vertical"
      onChange={(e) => {
        const {name, value} = e.target
        if (name === 'expression') {
          setExpression(value)
        }
        if (name === 'example') {
          setExample(value)
        }

        onInputChange(e)
      }}
      onFinish={() => {
        form.resetFields();
        setExpression('')
        setExample('')
        setHighlightedStr('')
        onFinish();
      }}
    >
      <Row>
        <Col>
          <Item
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={5}>
          <Item
              initialValue={""}
              label="Description"
              name="description"
          >
            <Input name="description" />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Item
            initialValue={""}
            label="Expression"
            name="expression"
            rules={[{ required: true, message: "Expression is required" }]}
          >
            <Input name="expression" />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Item
            initialValue={""}
            label="Example URL"
            name="example"
          >
            <Input name="example" />
          </Item>
        </Col>
        <span dangerouslySetInnerHTML={{__html: highlightedStr}}></span>
      </Row>
    </Form>
  );
}
