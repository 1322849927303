import React from "react";
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";
import {useModal} from "../../hooks";
import RemoteServiceForm from "../Forms/remote-service";
const { Item } = Form;


const parametersMapping = [
  {
    value: 'm',
    name: 'Manufacturer ID'
  },
  {
    value: 'c',
    name: 'Counter'
  },
  {
    value: 'state',
    name: 'State'
  },
  {
    value: 'authorized',
    name: 'Authorized'
  }
]

export default function RemoteAgent({ form, token }) {
  const [isModalShown, setModalShown, , onModalCancelAction] =
    useModal();
  const params = (form.getFieldValue(['settings', 'payloadParams']))
  const authUrl = (form.getFieldValue(['settings', 'authUrl']))

  return (
    <>
      <Modal
        title="Test remote parser"
        okText="Test"
        visible={isModalShown}
        onOk={() => {}}
        onCancel={() => {
          onModalCancelAction()();
        }}
        okButtonProps={{
          form: "test-remote-service",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <RemoteServiceForm  params={params} authUrl={authUrl} token={token}/>
      </Modal>
      <Item name={['settings']} style={{
        marginBottom: '0'
      }}>
        <Row>
          <Col span={24}>
            <Item
              name={['settings', 'authUrl']}
              label="Url"
              rules={[{ required: true, message: "Url required" }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>

        <Form.List name={['settings', 'payloadParams']} >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Row>
                  <Col span={24}>
                    <Row style={{alignItems: 'center'}}>
                      <Col span={8}>
                        <Item
                          name={[field.name, 'name']}
                          label="Response field"
                          rules={[{ required: true, message: "Response field required" }]}
                        >
                          <Input />
                        </Item>
                      </Col>
                      <Col offset={1} span={12}>
                        <Item
                          name={[field.name, 'value']}
                          label="Mapped value"
                          rules={[{ required: true, message: "Mapped value is required" }]}
                        >
                          <Select>
                            {parametersMapping.map(({name, value}) => (
                              <Select.Option value={value} key={value}>
                                {name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Item>
                      </Col>

                      {index > 0 ? (
                        <Col offset={1} span={2}>
                          <Button type="primary"  shape="circle" icon={<MinusOutlined />} onClick={() => {
                            remove(field.name)
                          }} danger ></Button>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              ))}

              <Row justify="center">
                <Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add response mapping
                  </Button>
                  <Button
                    style={{marginLeft: '8px'}}
                    type="primary"
                    onClick={setModalShown()}
                  >
                    Test
                  </Button>
                </Item>
              </Row>
            </>
          )}
        </Form.List>
      </Item>
    </>
  );
}
