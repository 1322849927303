import React, { useState, useEffect } from "react";
import {Row, Button, Modal, Form, notification} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

import { URLS } from "../../constants";
import { useModal } from "../../hooks";
import { AgentForm } from "../../components/Forms";
import { ContentInner, StyledTable } from "../../pages/styled";

export default function Agents({ token, environment }) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [regularExpressions, setRegularExpressions] = useState([]);
  // eslint-disable-next-line
  const [newAgent, setNewAgent] = useState({});
  const [currentAgent, setCurrentAgent] = useState({});
  const [itemToDelete, setItemToDelete] = useState(null);

  const initialValues = { name: "", description: "", type: "", settings: {} };
  const [isModalShown, setModalShown, onModalOkAction, onModalCancelAction] =
    useModal();
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOkAction,
    onEditModalCancelAction,
  ] = useModal();
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOkAction,
    onDeleteModalCancelAction,
  ] = useModal();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      with: 150,
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: 72,
      render: (_, row) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              onEditAgent(row);
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: 88,
      render: (_, row) => {
        return (
          <Button
            danger
            type="link"
            size="small"
            onClick={() => {
              setItemToDelete(row);
              setDeleteModalShown()();
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const getAgents = async () => {
    axios
      .get(URLS.FETCH_AGENTS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const getRegularExpressions = async () => {
    axios
      .get(URLS.FETCH_REGULAR_EXPRESSIONS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => {
        setRegularExpressions(res.data);
      });
  };

  const deleteAgentById = async () => {
    return axios
      .delete(`${URLS.FETCH_AGENTS}/${itemToDelete.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => res.data);
  };

  const onDelete = async () => {
    try {
      const response = await deleteAgentById();

      if (response) {
        const newData = data.filter((d) => {
          return d.id !== itemToDelete.id;
        });

        setData(newData);
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  const handleOk = () => {
    onDelete(itemToDelete);
    onDeleteModalOkAction()();
  };

  const onEditAgent = (data) => {
    setCurrentAgent({ ...data });
    setEditModalShown()();
  };

  const resetCurrentAgent = () => {
    setCurrentAgent(initialValues);
  };

  const showCreateModal = () => {
    resetCurrentAgent();
    setModalShown()();
  };

  const createAgent = async (agentData) => (
    axios
    .post(URLS.FETCH_AGENTS, agentData, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        env: environment,
      },
    })
    .then((res) => res.data)
  );

  const editAgent = async (agentData) => (
    axios
    .put(`${URLS.FETCH_AGENTS}/${agentData.id}`, agentData, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        env: environment,
      },
    })
    .then((res) => res.data)
  );

  const onFinishAdd = async (agentData) => {
    try {
      onModalOkAction()();
      setNewAgent(agentData)
      const response = await createAgent({...agentData, settings: JSON.stringify(agentData.settings)});

      if (response) {
        setNewAgent({});
        setData([response, ...data])
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  const onFinishEdit = async (agentData) => {
    try {
      onEditModalOkAction()();
      setCurrentAgent(agentData)
      const response = await editAgent({...agentData, settings: JSON.stringify(agentData.settings)});

      if (response) {
        const newData = data.map((item) => {
          if (response.id === item.id) {
            return {...response};
          }
          return item;
        });
        setData(newData);
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  useEffect(() => {
    if (!data.length) {
      getAgents();
      getRegularExpressions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  return (
    <>
      <Modal
        title="Delete agent"
        visible={isDeleteModalShown}
        onOk={handleOk}
        onCancel={onDeleteModalCancelAction()}
      >
        Are you sure you want to delete agent?
      </Modal>
      <Modal
        title="Create new agent"
        okText="Create agent"
        destroyOnClose={true}
        visible={isModalShown}
        onOk={() => {}}
        onCancel={() => {
          onModalCancelAction()();
        }}
        okButtonProps={{
          form: "agent-create-form",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <AgentForm
          form={form}
          token={token}
          regularExpressions={regularExpressions}
          onFinish={onFinishAdd}
        />
      </Modal>

      <Modal
        title="Edit agent"
        okText="Save changes"
        destroyOnClose={true}
        visible={isEditModalShown}
        onOk={() => {}}
        onCancel={() => {
          onEditModalCancelAction()();
          setCurrentAgent({});
        }}
        okButtonProps={{
          form: "agent-edit-form",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <AgentForm
          agent={currentAgent}
          isEdit={true}
          token={token}
          regularExpressions={regularExpressions}
          form={form}
          onFinish={onFinishEdit}
        />
      </Modal>
      <ContentInner>
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            showCreateModal();
          }}
          style={{ marginBottom: "25px" }}
        >
          Add new agent
        </Button>
        <Row justify="center" width="100%">
          <StyledTable
            style={{ width: "100%" }}
            columns={columns}
            rowKey="id"
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
          />
        </Row>
      </ContentInner>
    </>
  );
}
