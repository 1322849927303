import React, { useState } from "react";
import {Form, Input, Select, Row, Col, Button, Divider, Drawer} from "antd";
import {
  DeleteFilled,
  CopyFilled,
  QrcodeOutlined,
  MinusOutlined,
} from "@ant-design/icons";

import { SVGQRCodes } from ".";

const { Item } = Form;

export default function TagManagerForm({
  token,
  order,
  tagTypes,
  tagModels,
  tagManufacturer,
  companies,
  field,
  onInputChange,
  onOptionsChange,
  onCompanySelect,
  onTagTypeSelect,
  onTagModelSelect,
  onTagManufacturerSelect,
  onDelete,
  onClone,
  onReset,
  agents,
  form
}) {
  const [optionVisible, setOptionVisible] = useState(false);

  const getCompanyNameById = (id) => {
    const company = companies.find((company) => company.id === id);
    if (!company) {
      return "";
    }
    return company.name;
  };

  const onSearch = (input, option) =>
    getCompanyNameById(option.value)
      .toLowerCase()
      .includes(input.toLowerCase()) ||
    getCompanyNameById(option.value)
      .toLowerCase()
      .includes(input.toLowerCase());

  const sortedCompaniesByName = companies.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <>
      <Row>
        <Col span={11}>
          <Item label="Company" name={[field.name, 'companyId']}
                rules={[{required: true, message: "Company is required"}]}>
            <Select
              showSearch
              optionFilterProp="value"
              filterOption={(input, option) => onSearch(input, option)}
              onSelect={onCompanySelect}
              value={getCompanyNameById(order.companyId)}
            >
              {sortedCompaniesByName.map((company) => (
                <Select.Option value={company.id} key={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col offset={2} span={11}>
          <Item label="Manufacturer" name={[field.name, 'tagManufacturerId']}
                rules={[{required: true, message: "Manufacturer is required"}]}>
            <Select
              onSelect={onTagManufacturerSelect}
              value={order.tagManufacturerId}
            >
              {tagManufacturer.map((manufacturer) => (
                <Select.Option value={manufacturer.id} key={manufacturer.id}>
                  {manufacturer.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Item label="Number of tags" name={[field.name, 'size']}
                rules={[{required: true, message: "Size is required"}]} onChange={onInputChange}>
            <Input name="size" placeholder="20" onChange={onInputChange}/>
          </Item>
        </Col>
        <Col offset={1} span={7}>
          <Item label="Overage" name={[field.name, 'overage']}
                rules={[{required: true, message: "Overage is required"}]}>
            <Input
              suffix="%"
              name="overage"
              placeholder="5"
              onChange={onInputChange}
            />
          </Item>
        </Col>
        <Col offset={1} span={7}>
          <Item label="Rolls" name={[field.name, 'rolls']}
                rules={[{required: true, message: "Rolls is required"}]}>
            <Input name="rolls" placeholder="5" onChange={onInputChange}/>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Item label="Tag type" name={[field.name, 'tagTypeId']}
                rules={[{required: true, message: "Tag type is required"}]}>
            <Select onChange={onTagTypeSelect} value={order.tagTypeId}>
              {tagTypes.map((type) => (
                <Select.Option value={type.id} key={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col offset={2} span={16}>
          <Item label="Tag model" name={[field.name, 'tagModelId']}
                rules={[{required: true, message: "Tag model is required"}]}>
            <Select
              disabled={!order.tagTypeId}
              value={order.tagModelId}
              onSelect={onTagModelSelect}
            >
              {tagModels.filter((model) => model.tag_type.id === order.tagTypeId).map((model) => (
                <Select.Option value={model.id} key={model.id}>
                  {model.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Item label="Description" name={[field.name, 'description']}>
        <Input
          name="description"
          placeholder="description"
          onChange={onInputChange}
        />
      </Item>

      <div style={{
        border: '1px solid #d9d9d9',
        padding: '12px',
        marginBottom: '24px'
      }}>
        <Form.List name={[field.name, 'agents']}>
          {(fields, {add, remove}, {errors}) => (
            <>
              {fields.map((agentField, index) => {
                const agent = order.agents[agentField.name]
                const typeMapping = {
                  parserAgent: 'parser',
                  remoteAgent: 'remote'
                }

                const availableAgents = agents.filter((item) => item?.type === typeMapping[agent?.type])
                return (
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <Item
                            name={[agentField.name, 'type']}
                            label="Agent type"
                            rules={[{required: true, message: "Agent type required"}]}
                          >
                            <Select onChange={() => {
                              form.resetFields([['orders', field.name, 'agents', agentField.name, 'agentId']])
                            }}>
                              <Select.Option value="parserAgent" key="parserAgent">
                                Parser
                              </Select.Option>
                              <Select.Option value="remoteAgent" key="remoteAgent">
                                Remote
                              </Select.Option>
                            </Select>
                          </Item>
                        </Col>
                        <Col offset={1} span={8}>
                          <Item
                            name={[agentField.name, 'agentId']}
                            label="Agent"
                            rules={[{required: true, message: "Agent is required"}]}
                          >
                            <Select disabled={!agent?.type} >
                              {availableAgents.map(({name, id}) => (
                                <Select.Option value={id} key={id}>
                                  {name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Item>
                        </Col>

                        <Col offset={1} span={2}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<MinusOutlined/>}
                            onClick={() => {
                              remove(agentField.name)
                            }}
                            danger
                          >
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              })}

              <Row justify="center">
                <Item style={{
                  margin: '0'
                }}>
                  <Button disabled={order?.agentsAmount} onClick={() => {
                    add()
                  }}>
                    Add agent
                  </Button>
                </Item>
              </Row>
            </>
          )}

        </Form.List>
      </div>

      <Row>
        <Row style={{width: "19%"}}>
          <Button
            icon={<QrcodeOutlined/>}
            onClick={() => setOptionVisible(true)}
          >
            QRC Options
          </Button>
          <Drawer
            width={500}
            title="QRC Options"
            placement="right"
            closable={false}
            onClose={() => setOptionVisible(false)}
            visible={optionVisible}
          >
            <SVGQRCodes
              order={order}
              onReset={onReset}
              onOptionsChange={onOptionsChange}
            />
          </Drawer>
        </Row>
        <Row justify="end" style={{width: "81%"}}>
          <Button
            style={{marginRight: "1rem"}}
            type="primary"
            icon={<CopyFilled/>}
            onClick={onClone}
          >
            Clone
          </Button>
          <Button type="danger" icon={<DeleteFilled/>} onClick={onDelete}>
            Delete
          </Button>
        </Row>
      </Row>
      <Divider/>
    </>
  );
}
