import React, { useState, useEffect } from "react";
import { Row, Button, Modal, Form, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

import { URLS } from "../../constants";
import { useModal } from "../../hooks";
import { TagModelForm } from "../../components/Forms";
import { ContentInner, StyledTable } from "../../pages/styled";

export default function TagModels({ token, environment }) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);
  const [newTagModel, setNewTagModel] = useState({});
  const [currentTagModel, setCurrentTagModel] = useState({});
  const [itemToDelete, setItemToDelete] = useState(null);

  const initialValues = { name: "", description: "" };
  const [isModalShown, setModalShown, onModalOkAction, onModalCancelAction] =
    useModal();
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOkAction,
    onEditModalCancelAction,
  ] = useModal();
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOkAction,
    onDeleteModalCancelAction,
  ] = useModal();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "tag_type",
      key: "tag_type",
      width: 150,
      render: (val, row) => {
        return val.name;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      with: 150,
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: 72,
      render: (_, row) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              onEditTagModel(row);
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: 88,
      render: (_, row) => {
        return (
          <Button
            danger
            type="link"
            size="small"
            onClick={() => {
              setItemToDelete(row);
              setDeleteModalShown()();
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const getTagModels = async () => {
    axios
      .get(URLS.FETCH_TAG_MODELS, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const getTagTypes = async () => {
    axios
      .get(URLS.FETCH_TAG_TYPES, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => {
        setTagTypes(res.data);
      });
  };

  const deleteTagModelById = async () => {
    return axios
      .delete(`${URLS.FETCH_TAG_MODELS}/${itemToDelete.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          env: environment,
        },
      })
      .then((res) => res.data);
  };

  const onDelete = async () => {
    try {
      const response = await deleteTagModelById();

      if (response) {
        const newData = data.filter((d) => {
          return d.id !== itemToDelete.id;
        });

        setData(newData);
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  const handleOk = () => {
    onDelete(itemToDelete);
    onDeleteModalOkAction()();
  };

  const onEditTagModel = (data) => {
    setCurrentTagModel({ ...data });
    setEditModalShown()();
  };

  const updateTagModel = (name, value, isNew) => {
    if (isNew) {
      setNewTagModel({ ...newTagModel, [name]: value });
    } else {
      setCurrentTagModel({ ...currentTagModel, [name]: value });
    }
  };

  const onInputChange = (isNew) => (e) => {
    const { name, value } = e.target;
    updateTagModel(name, value, isNew);
  };

  const resetCurrentTagModel = () => {
    setCurrentTagModel(initialValues);
  };

  const showCreateModal = () => {
    resetCurrentTagModel();
    setModalShown()();
  };

  const createTagModel = async () => (
    axios
    .post(URLS.FETCH_TAG_MODELS, newTagModel, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        env: environment,
      },
    })
    .then((res) => res.data)
  );

  const editTagModel = async () => (
    axios
    .put(`${URLS.FETCH_TAG_MODELS}/${currentTagModel.id}`, currentTagModel, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        env: environment,
      },
    })
    .then((res) => res.data)
  );

  const onFinishAdd = async () => {
    try {
      onModalOkAction()();
      const response = await createTagModel();

      if (response) {
        setNewTagModel({});
        setData([response, ...data])
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  const onFinishEdit = async () => {
    try {
      onEditModalOkAction()();
      const response = await editTagModel();

      if (response) {
        const newData = data.map((item) => {
          if (response.id === item.id) {
            return {...response};
          }
          return item;
        });
        setData(newData);
      }
    } catch (e) {
      const message = e?.response?.data?.message || e?.message
      notification.error({
        message: 'Request error',
        description: message
      });
    }
  };

  useEffect(() => {
    if (!data.length) {
      getTagModels();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!tagTypes.length) {
      getTagTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagTypes.length]);

  return (
    <>
      <Modal
        title="Delete tag model"
        visible={isDeleteModalShown}
        onOk={handleOk}
        onCancel={onDeleteModalCancelAction()}
      >
        Are you sure you want to delete tag model?
      </Modal>
      <Modal
        title="Create new tag model"
        okText="Create tag model"
        visible={isModalShown}
        onOk={() => {}}
        onCancel={() => {
          onModalCancelAction()();
        }}
        okButtonProps={{
          form: "tag-model-create-form",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <TagModelForm
          form={form}
          tagTypes={tagTypes}
          onFinish={onFinishAdd}
          onInputChange={onInputChange(true)}
          onTypeChange={(value) => {
            updateTagModel('typeId', value, true);
          }}
        />
      </Modal>

      <Modal
        title="Edit tag model"
        okText="Save changes"
        visible={isEditModalShown}
        onOk={() => {}}
        onCancel={() => {
          onEditModalCancelAction()();
          setCurrentTagModel({});
        }}
        okButtonProps={{
          form: "tag-model-edit-form",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <TagModelForm
          tagModel={currentTagModel}
          tagTypes={tagTypes}
          form={form}
          isEdit={true}
          onFinish={onFinishEdit}
          onInputChange={onInputChange()}
          onTypeChange={(value) => {
            updateTagModel('typeId', value, false);
          }}
        />
      </Modal>
      <ContentInner>
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            showCreateModal();
          }}
          style={{ marginBottom: "25px" }}
        >
          Add new tag model
        </Button>
        <Row justify="center" width="100%">
          <StyledTable
            style={{ width: "100%" }}
            columns={columns}
            rowKey="id"
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
          />
        </Row>
      </ContentInner>
    </>
  );
}
