import React, { useEffect } from "react";
import { Form, Input, Row, Col } from "antd";

const { Item } = Form;

export default function TagTypeForm({
  isEdit,
  tagModel,
  onInputChange,
  onFinish
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(tagModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagModel]);

  return (
    <Form
      form={form}
      id={isEdit ? "tag-type-edit-form" : 'tag-type-create-form'}
      layout="vertical"
      onChange={onInputChange}
      onFinish={() => {
        form.resetFields();
        onFinish();
      }}
    >
      <Row>
        <Col>
          <Item
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={5}>
          <Item
              initialValue={""}
              label="Description"
              name="description"
          >
            <Input name="description" />
          </Item>
        </Col>
      </Row>
    </Form>
  );
}
