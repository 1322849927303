export const EVENTS = {
  TAG_MANAGER_CREATE: "tag-manager:create",
};

export const TAG_TYPES = {
  qrc: ["qrcalphanumeric"],
  nfc: ["ndef", "ntag210", "ntag210u", "ntag213", "ntag215", "ntag216", "smartrac", "sic43s1", "sic43nt", "nxp424dna", "nxpntag213", "stmst25tv02kc"],
};

export const TAG_AUTH_SERVICE = {
  sic_cmac: "SIC CMAC",
  sic_tamper: "SIC Tamper",
  identiv_u_create: "Identiv uCreate",
};

export const TAG_MANUFACTURER = ["nxp", "tapwow", "identiv", "st"];

export const ROLL_MODEL = {
  companyId: "",
  size: "",
  overage: "",
  rolls: "",
  description: "",
  tagType: "",
  tagModel: "",
  tagManufacturer: "",
  qrc: {
    padding: "0",
    width: "200px",
    height: "200px",
    fgColor: "#000000",
    bgColor: "#ffffff",
  },
};

export const ENVIRONMENT_MODEL = {
  name: "",
  port: "",
  host: "",
  username: "",
  password: "",
  database: "",
  client: "",
  serverUrl: "",
  description: "",
};

export const DATABASE_TYPES = {
  PostgreSQL: "pg",
};

export const URLS = {
  FETCH_ENVIRONMENT: `${process.env.REACT_APP_SERVER_HOST}/environment`,
  SAVE_ENVIRONMENT: `${process.env.REACT_APP_SERVER_HOST}/environment`,
  DELETE_ENVIRONMENT: `${process.env.REACT_APP_SERVER_HOST}/environment`,
  TEST_ENVIRONMENT_CONNECTION: `${process.env.REACT_APP_SERVER_HOST}/connection`,
  FETCH_COMPANIES: `${process.env.REACT_APP_SERVER_HOST}/company`,
  FETCH_LOGIN: `${process.env.REACT_APP_SERVER_HOST}/auth/login`,
  VERIFY_TOKEN: `${process.env.REACT_APP_SERVER_HOST}/auth/verify`,
  FETCH_ORDERS: `${process.env.REACT_APP_SERVER_HOST}/bucket-links`,
  FETCH_USERS: `${process.env.REACT_APP_SERVER_HOST}/user`,
  FETCH_CURRENT_USER: `${process.env.REACT_APP_SERVER_HOST}/user/current`,
  MANIFEST: `${process.env.REACT_APP_SERVER_HOST}/manifest`,
  FETCH_TAG_MODELS: `${process.env.REACT_APP_SERVER_HOST}/tag-settings/tag-models`,
  FETCH_TAG_MANUFACTURERS: `${process.env.REACT_APP_SERVER_HOST}/tag-settings/tag-manufacturers`,
  FETCH_TAG_TYPES: `${process.env.REACT_APP_SERVER_HOST}/tag-settings/tag-types`,
  TEST_REMOTE_API: `${process.env.REACT_APP_SERVER_HOST}/remote-api/test-remote`,
  FETCH_REGULAR_EXPRESSIONS: `${process.env.REACT_APP_SERVER_HOST}/tag-settings/regular-expressions`,
  FETCH_AGENTS: `${process.env.REACT_APP_SERVER_HOST}/tag-settings/agents`,
};
