import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";

const { Item } = Form;

export default function ActivationParamsForm({
  params
}) {
  const [testParams, setTestParams] = useState([])
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      id="test-activation-params"
      form={form}
      onFinish={() => {
        const items = params.map((param) =>{
          const link = form.getFieldValue('link')
          // eslint-disable-next-line no-unused-vars
          const [_, parsedVal] = link.match(new RegExp(param.value)) || []
          let valueMapped = ''
          if (param.valueMappings) {
            param.valueMappings.forEach(({valueRegexp, valueMapping}) => {
              if (new RegExp(valueRegexp).test(parsedVal)) {
                valueMapped = valueMapping
              }
            })
          }
          return {
            parameter: param.name,
            value: parsedVal,
            valueMapped
          }
        })
        setTestParams(items)
      }}>
      <Row>
        <Col span={24}>
          <Item
            initialValue={""}
            label="Activation link"
            name="link"
            rules={[{ required: true, message: "Link is required" }]}
          >
            <Input name="link" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <strong>Parameter</strong>
        </Col>
        <Col span={8}>
          <strong>Value</strong>
        </Col>
        <Col span={8}>
          <strong>Value mapped</strong>
        </Col>
      </Row>
      {testParams.map(({parameter, value, valueMapped}) => {
        return (
          <Row>
            <Col span={8}>
              {parameter}
            </Col>
            <Col span={8}>
              {value}
            </Col>
            <Col span={8}>
              {valueMapped}
            </Col>
          </Row>
        )
      })}
    </Form>
  );
}
