import React, { useState } from "react";
import {Form, Input, Row, Col, notification} from "antd";
import axios from "axios";
import {URLS} from "../../constants";

const { Item } = Form;

export default function RemoteServiceForm({
  onFinish,
  params = [],
  authUrl,
  token
}) {
  const [responseData, setResponseData] = useState({})
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      id="test-remote-service"
      form={form}
      onFinish={() => {
        // onFinish();
        const searchParams = new URL(form.getFieldValue('link')).searchParams;
        const params = Object.fromEntries(searchParams)
        axios.post(URLS.TEST_REMOTE_API, {
          authUrl,
          params
        }, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          setResponseData(res.data);
        }).catch((e) => {
          const message = e?.response?.data?.message || e?.message
          notification.error({
            message: 'Request error',
            description: message
          });
        });
      }}>
      <Row>
        <Col span={24}>
          <Item
            initialValue={""}
            label="Activation link"
            name="link"
            rules={[{ required: true, message: "Link is required" }]}
          >
            <Input name="link" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <strong>Response fields</strong>
        </Col>
        <Col span={8}>
          <strong>Mapped field</strong>
        </Col>
        <Col span={8}>
          <strong>Value</strong>
        </Col>
      </Row>
      {Object.keys(responseData).map((key) => {
        const param = params.find((item) => item.name === key)

        return (
          <Row>
            <Col span={8}>
              {key}
            </Col>
            <Col span={8}>
              {param?.value}
            </Col>
            <Col span={8}>
              {responseData[key]?.toString()}
            </Col>
          </Row>
        )
      })}
    </Form>
  );
}
