import styled from "styled-components";
import { Table } from "antd";

export const ContentInner = styled.div`
  display: flex;
  width: 50%;
  min-width: 700px;
  margin-top: 24px;
  flex-direction: column;
  align-self: center;
`;

export const TopControlsBlock = styled.div`
  display: flex;
  width: ${({ width }) => width || "35%"};
  justify-content: space-between;
  align-self: ${({ align }) => align || "flex-end"};
`;

export const Link = styled.a`

`;

export const StyledTable = styled(Table)`
  .ant-table-thead th {
    font-weight: 600;
  }
`;
